import { FunctionComponent, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../_Components/Footer";
import NavHeader from "../_Components/NavHeader";
import "./UploadFile.css";
import React from "react";
import { useDropzone } from "react-dropzone";
import FileInputBox from "../_Components/FileInputBox";
import { Trash2 } from "react-feather";
import axios from "axios";
import config from "../config";
import PopupModal from "../_Components/PopupModal";
import { useParams } from "react-router-dom";
const UploadFile: FunctionComponent = () => {
  const navigate = useNavigate();
  const { cat } = useParams();
  const onGroupContainerClick = useCallback(() => {
    navigate("/career");
  }, [navigate]);

  const hedImg = "/assets/images/carrer/hLogoGr.svg";

  const image_input_ref = useRef<HTMLInputElement | null>(null);
  const [name, setName] = useState("");
  const [isValidName, setIsValidName] = useState(true);
  const [email, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [doneMessage, setDoneMessage] = useState("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setSelectedImage(acceptedFiles[0]);
    },
  });

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const formattedInput = input.replace(/[^A-Za-z\s]/g, "").substring(0, 25);
    setName(formattedInput);
    setIsValidName(formattedInput.trim().length > 0);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setEmail(input);
  };

  const handleInputBlur = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setIsValid(emailRegex.test(email));
  };

  const handleMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    // Remove any non-digit characters from the input
    const sanitizedInput = input.replace(/\D/g, "");
    // Limit the input to 10 digits
    const truncatedInput = sanitizedInput.slice(0, 10);
    setMobileNumber(truncatedInput);
  };
  const clearRef = () => {
    if (image_input_ref.current) {
      image_input_ref.current.value = ""; // Clear the input value
    }
  };
  const activate_image_input = () => {
    if (selectedImage) {
      setErrorMessage("You cannot upload more than 1 file at a time!");
      return;
    }

    if (image_input_ref.current) {
      image_input_ref.current.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setErrorMessage("Please upload a file smaller than 10MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);

      const fileType = file.type;
      if (
        fileType === "application/pdf" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        console.log("filetype");
        setSelectedImage(file);
      } else {
        setErrorMessage("We Recomend  only one PDF or Word document.");
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!name || !email || !mobile) {
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", mobile);

    if (selectedImage) {
      formData.append("resume", selectedImage);
    }

    try {
      const response = await axios.post(
        `${config.baseUrl}/api/v1.0/uploadfile/${cat}`,
        formData,
      );

      console.log(response.data);
      setDoneMessage(response.data.message);
      setName('');
      setEmail('');
      setMobileNumber('');
      setSelectedImage(null);
      setTimeout(() => {
        navigate("/team")
      }, 3000);
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  const handleCloseModal = () => {
    setErrorMessage("");
    setDoneMessage("");
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    clearRef()
  };
  return (
    <>
      <NavHeader imageUrl={hedImg} />
      <div className="d-flex flex-column">
        <div className="main-con">
          <div className="">
            <div className="fs-1 mt-4">Upload Your Resume</div>
            <div className="mt-4 mb-4 text-secondary">
              Select relevant documents and upload below
            </div>
            <div className="selector d-flex flex-column">
              <img
                className="mt-2 align-self-sm-center"
                alt=""
                src="/featheruploadcloud.svg"
              />
              <div className="d-flex flex-column align-items-sm-center">
                <div className="">
                  <div
                    {...getRootProps()}
                    className={`file-input-box ${isDragActive ? "drag-active" : ""
                      }`}
                  >
                    <input
                      {...getInputProps()}
                      multiple
                      ref={image_input_ref}
                      type="file"
                      id="select__video"
                      className={`file-input-box ${isDragActive ? "drag-active" : ""
                        }`}
                      onChange={(e) => {
                        handleImageChange(e);
                      }}
                    />
                    {selectedImage ? (
                      <div >
                        <p>Selected File: {selectedImage.name}</p>
                        <button className="mt-2 p-2 border-0  ms-4 text-danger rounded" onClick={handleImageDelete}>
                          <Trash2 size={16} /> Delete
                        </button>
                      </div>
                    ) : (
                      <p>Select a file or drag and drop here</p>
                    )}
                  </div>
                  <div className="text-secondary mt-2">
                    PDF or Document, file size no more than 10MB
                  </div>
                </div>

                <button
                  onClick={activate_image_input}
                  className="mt-5 border rounded-3 w-50 ps-3 p-3 glow animate"
                >
                  Select file
                </button>
              </div>
              {errorMessage && (
                <PopupModal
                  errorMessage={errorMessage}
                  onClose={handleCloseModal}
                  heading="Error"
                  cssClass={"text-danger"}
                />
              )}
              {doneMessage && (
                <PopupModal
                  errorMessage={doneMessage}
                  onClose={handleCloseModal}
                  heading="Success"
                  cssClass={"text-success"}
                />
              )}
            </div>

            <div className="d-flex flex-column p-4 mt-5 border rounded mb-5">
              <input
                type="text"
                placeholder="Name"
                className="mt-3 p-2 rounded border border-grey txts"
                value={name}
                onChange={handleName}
              />
              <input
                className="mt-3 p-2 rounded border border-grey txts"
                value={email}
                placeholder=" e-mail ID*"
                onBlur={handleInputBlur}
                onChange={handleEmail}
              />
              {!isValid && (
                <span className="text-danger">Please enter a valid Email</span>
              )}
              <input
                type="number"
                placeholder="Mobile Num"
                className="mt-3 p-2 rounded border border-grey txts"
                value={mobile}
                onChange={handleMobile}
              />
              <div className="d-flex mt-4">
                <button
                  className="glow animate mt-5 border rounded-3 w-50 ps-3 p-3"
                  onClick={onGroupContainerClick}
                >
                  Cancel
                </button>
                <button
                  className="glow animate mt-5 border rounded-3 w-50 ps-3 p-3 ms-3 sendBtn"
                  onClick={handleSubmit}
                >
                  <span>
                    Send
                    <img className="ms-2" alt="" src="/vector5.svg" />
                  </span>
                </button>
                <i></i>
              </div>
            </div>
          </div>

          <p className="">
            Please note that for any employment verification or experience
            letter,
          </p>
          <p className="">
            it will be duly attested by both the MD and HR of OSOS.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UploadFile;
