import React from 'react';
import "./PopupModal.css"
interface PopupModalProps {
    errorMessage: string;
    onClose: () => void;
    heading: string;
    cssClass: string;
}

const PopupModal: React.FC<PopupModalProps> = ({ errorMessage, onClose, heading, cssClass }) => {
    return (
        <div className="popup-modal ">
            <div className="popup-modal-content">
                <h3 className={`${cssClass}`}>{heading}</h3>
                <p>{errorMessage}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default PopupModal;
