import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomeScreen from "./components/HomeScreen";
import UploadFile from "./pages/UploadFile";
import Career from "./pages/Career";
import ProductMain from "./pages/ProductMain";
import Team from "./pages/Team";
import { useEffect } from "react";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/frame-1171275027":
        title = "";
        metaDescription = "";
        break;
      case "uploadFile":
        title = "";
        metaDescription = "";
        break;
      case "/career":
        title = "";
        metaDescription = "";
        break;
      case "/career1":
        title = "";
        metaDescription = "";
        break;
      case "/product-main":
        title = "";
        metaDescription = "";
        break;
      case "/home-screen1":
        title = "ososweb";
        metaDescription = "ososweb";
        break;
      case "/team":
        title = "OSOS Devlopment Team";
        metaDescription = "osos devlopment team";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      {/* <Route path="/home" element={<HomeScreen />} /> */}
      {/* <Route path="/UploadFile" element={<UploadFile />} /> */}
      <Route path="/UploadFile/:cat" element={<UploadFile />} />
      <Route path="/career" element={<Career />} />
      <Route path="/product-main" element={<ProductMain />} />
      <Route path="/team" element={<Team />} />
    </Routes>

  );
}
export default App;
