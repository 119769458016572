import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Team.css";
import Footer from "../_Components/Footer";
import NavHeader from "../_Components/NavHeader";
const Team: FunctionComponent = () => {

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShowDiv(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const navigate = useNavigate();
  const circleLogo = "/assets/images/Team/asisSir.svg"
  const bgImg = "/assets/images/Team/tmBg.svg"
  const t1 = "/assets/images/Team/tt1.png"
  const t2 = "/assets/images/Team/tt2.png"
  const divStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    maxWidth: '1920px',
    maxHeight: '1100px',
    paddingTop: "80px",
  };
  const hedImg = "/assets/images/carrer/hLogoGr.svg"
  return (
    <>
      <NavHeader imageUrl={hedImg} />

      <div style={divStyle} className="">
        <div className="col-12 ptb-100">
          <div className="row ptb-100">

            <div className="col  ms-5 mt-5  me-2">
              <div className="txt row ptb-100 teamTxt  mt-4 me-3">
                <div >Our
                  <span className="ms-2" style={{ color: "#8CC63F" }}>team</span>
                </div>

              </div>
              <div>
                <p className="pText  tTexts ">
                  With a unique blend of technical expertise and creative vision,
                  we are committed to crafting
                  exceptional applications that elevate the way we interact with technology.
                  Through collaboration and innovation,
                  we strive to deliver user experiences that are intuitive,
                  engaging, and inspiring.
                </p>
              </div>
            </div>
            <div className="col-8">
              <div className="container-fluid">
                <div className="row">
                  <img src={t1} alt="team-image" className="cover img1  ms-4 cover " />
                  <img src={t2} alt="team-images" className="cover img2 cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mobmarg">
        <div className="sub-con">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column justify-content-center ps-5 ">
                <img src={circleLogo} alt="" className="golLogo ms-2" />
                <span className="subTextTeam">Ashish kumar jain</span>
              </div>
            </div>
            <div className="col">
              <div className="headText mb-5">Managing Director</div>
              <div className="m-text">
                <p>
                  ashish Jain began his tenure as Managing Director of OSOS private Limited, on December 3rd,2022.
                </p>
                <p>
                  As a current CEO & Managing Director of www.medgini.com leading digital marketing agency serving India pharma industry since 2012, ASHISH JAIN has led multiple roles to scale business to new heights. Expertise in Digital Marketing, Product Management, Business Development, Strategic Partnerships, Development and Operations in the past 16 years in companies of all sizes from early-stage startups to multi-billion-dollar multinational companies, his experience can accelerate businesses with positive outcomes while guiding and coaching through the most difficult times.
                </p>
                <p>
                  Ashish Jain has previously held senior positions at Airtel, Wipro Limited, British Telecom. He has also spent several years at technology startups and founded his own firm, SAKSHAM INNOVATIONS PRIVATE LIMITED. Recently being recognised by BUSINESS WORLD HEALTHCARE – 40 Under 40 for his immense contribution in healthcare domain
                </p>
                Ashish Jain holds a master in Marketing Communication from Symbiosis Institute of Business Management from Pune, India in the year 2006
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerFix">
        <Footer />
      </div>


    </>
  );
};

export default Team;
