import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../_Components/Footer";
import NavHeader from "../_Components/NavHeader";
import Typewriter from 'typewriter-effect';

import "./HomeScreen.css";

const HomeScreen: FunctionComponent = () => {
    const bgImg = '/assets/images/homeBg.svg'
    const divStyle = {
        backgroundImage: 'url(path/to/image.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        maxWidth: '1500px',
        maxHeight: "800px"
    };
    const hImg = "/rocketlaunch3.svg"
    const vidd = "/assets/images/maker.webp"
    // const backgroundStyle = {
    //     backgroundImage: `url(${vidd})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     height: '100vh',
    //     margin: "auto"
    // };
    const backgroundStyle = {
        backgroundImage: `url(${vidd})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh',
        margin: 'auto',
        // position: 'relative',
    };





    const [isMobile, setIsMobile] = useState<boolean>(false)
    //cheking whether mobile or web screen 
    useEffect(() => {
        function isMobileDevice() {
            return window.innerWidth <= 768; // Adjust the width threshold as per your requirements
        }

        if (isMobileDevice()) {
            setIsMobile(true)
        } else {
            // console.log("Web browser");
            setIsMobile(false)
        }
    }, [])
    const homeBg = "/homeBg.png"
    const bgMountain = {
        backgroundImage: `url(${homeBg})`,
        backgroundSize: 'contain ',
        backgroundRepeat: 'no-repeat',
        maxHeight: "1700px",
        maxWidth: "100%",
        margin: "auto",
        alignSelf: "center",
        backgroundPosition: "center",

    };

    return (
        <>

            <NavHeader imageUrl={hImg} />
            <div className="container-fluid">
                <div className="row">
                    <div style={backgroundStyle}>
                        <div className="overlayStyle"></div>
                        <div className="d-flex justify-content-center" >
                            <div className="txtArea">
                                <div className="line-one z-2                            ">
                                    <span className="homTxt">
                                        Our goal is to create
                                    </span>
                                    <span className="bgGreen homTxt ps-1 pe-1">
                                        <Typewriter
                                            options={{
                                                strings: ['Clear', 'Innovative', 'and Useful '],
                                                autoStart: true,
                                                loop: true,
                                            }}
                                        />
                                    </span>
                                </div>

                                <div className="software-products shake-element">software products</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <section>
                <div className="caontainer-fluid">
                    <div className="col">
                        <div className="top-m-txt">
                            <div className="textM">Mission</div>
                            <div className="paraTxt">
                                We passionately develop software products that are user-friendly, innovative, and <br />
                                serve real-world needs, making everyday tasks easier and more enjoyable.
                            </div>
                        </div>

                    </div>
                </div>
                <div style={bgMountain} className="bgControll cover">
                    <div className="top-v-txt">
                        <div className="textV">Vision</div>
                        <div className="paraTxt2">
                            We strive to create software products that are simple, practical, and
                            intuitive, transforming the way people use technology and making their lives
                            easier in the process.
                        </div>
                    </div>
                    <div className="bgtxt-con txxxx mobController ">
                        <div className="bgTX ">
                            Discover who we are and what we do
                        </div>
                        <div className="subConTT">
                            <div className="mt-5 mb-3">Business with purpose.</div>

                            <div className="mb-2">We are a purpose driven company and fast growing.</div>
                            Social networking has become an essential part of our lives.We are certain that Social Networking services can be used in more
                            advantageous ways in our daily lives.

                            <div className="mt-5 mb-5"> Our application, offers cutting-edge and distinctive services to all mobile internet users.</div>
                        </div>
                    </div>

                </div>
                <Footer />
            </section>
        </>
    )
}
export default HomeScreen;