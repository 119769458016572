import { useEffect, useState } from "react";
import "./Footer.css";
const Footer: React.FC = () => {
  return (
    <div className="container-fluid bg-dark">
      <div className="d-flex flex-column">
        <div className="con-txt">Contact us</div>
        <div className="mail-txt">info@ososweb.com</div>
        {/* footer round logos  */}
        <div className="col m-auto text-light mt-5">
          <a href=" https://www.facebook.com/Spaarksapp">
            <img className=" homeHover " alt="facebook icon" src="fbIcon.svg" />
          </a>

          <a href=" https://twitter.com/Spaarks_app">
            <img
              className="homeHover ms-4"
              alt="twitter"
              src="twiterIcon.svg"
            />
          </a>

          <a href=" https://www.linkedin.com/company/spaarks-app/">
            <img className="homeHover ms-4" alt="linkedin" src="linkIcon.svg" />
          </a>
        </div>
        <div className="alll mt-5 pb-4">
          All Rights Reserved ososweb.com 2023
        </div>
      </div>
    </div>
  );
};
export default Footer;

const footerContainer = {};
