import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Career.css";
import Footer from "../_Components/Footer";
import NavHeader from "../_Components/NavHeader";
import axios from "axios";
const Career: FunctionComponent = () => {
  const navigate = useNavigate();
  const [jobList, setJobList] = useState<any | null>(null); // Use any or specify the proper type
  const [visibleItems, setVisibleItems] = useState(6);

  const bgCarrImg = "/assets/images/carrer/carr.png"

  const applyNow = (event: any, cat: string) => {
    navigate(`/UploadFile/${cat}`);
  };

  useEffect(() => {
    // Make the API call when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend.ososweb.com/api/v1.0/jobs');
      setJobList(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const targetDivRef = useRef<HTMLDivElement>(null);
  const scrollToDiv = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const remainingItems = jobList?.job?.length - visibleItems;
  const showMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 3);
  };
  const hedImg = "/assets/images/carrer/hLogoGr.svg"
  return (
    <>
      <NavHeader imageUrl={hedImg} />
      <div className="container-fluid">
        <div className="main-sub-con">
          <div className="Ctexts">
            Looking for a place to unleash your <br />
            potential and be part of an awesome team?
            <div className="subCar">
              Let’s spread awesomeness together!
            </div>
            <button className="appBtn mb-3" onClick={scrollToDiv}>APPLY NOW</button>
          </div>
          <div className="col-6 ">
            <img src={bgCarrImg} alt="" className="carrImgBg" />
          </div>
        </div>
        <section>
          <div className="container-fluid ptb-100">
            <div className="col m-5">
              <div className="job-sub-con">
                <h2 className="job-role">Job Roles</h2>

                <div className="jobCard-con ">
                  {
                    jobList && jobList.job.slice(0, visibleItems).map((jobs: any, index: any) => {
                      return (
                        <div className=" " key={index} ref={targetDivRef}>
                          <div className="jobCards">
                            <div>{jobs.title}</div>
                            <span className="text-primary" onClick={(event) => applyNow(event, jobs.title)}>Apply</span>
                          </div>
                        </div>
                      )
                    })
                  }
                  {remainingItems > 0 && (
                    <span className="text-primary pointer ms-auto animate txtGlowfw-semibold" onClick={showMoreItems}>Show More</span>
                  )
                  }
                </div>

              </div>

            </div>
            <div className="ft-txt ptb-100">
              Please note that for any employment verification or experience letter,<br />
              it will be duly attested by both the MD and HR of OSOS.
            </div>
          </div>
        </section>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Career;
//typescript modals
export interface jobs {
  job: Job[]
}

export interface Job {
  _id: string
  title: string
  description: string
  created_at: string
  status: string
}
