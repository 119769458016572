import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductMain.css";
import NavHeader from "../_Components/NavHeader";
import axios from "axios";
import config from "../config";
import PopupModal from "../_Components/PopupModal";

const ProductMain: FunctionComponent = () => {
  const navigate = useNavigate();

  //jkkhkjbjbjbjb
  const onGroupContainer49Click = useCallback(() => {
    window.open("https://www.spaarksweb.com/");
  }, []);
  const BlueBg = "/assets/images/products/productBg.png";
  const webImgg = "/assets/images/products/wenIc.svg";
  const girlF = "/assets/images/products/girlff.png";
  const mob = "/assets/images/products/mob.png";
  const mob2 = "/assets/images/products/mob2.png";
  const mob3 = "/assets/images/products/mob3.png";
  const mobMac = "/assets/images/products/mobMac.png";
  const line = "/assets/images/products/line.svg";
  const card1 = "/assets/images/products/card1.svg";
  const card2 = "/assets/images/products/card2.svg";
  const card3 = "/assets/images/products/card3.svg";
  const card4 = "/assets/images/products/card4.svg";
  const card5 = "/assets/images/products/card5.svg";
  const card6 = "/assets/images/products/card6.svg";
  const footBg = "/rectangle-34624194@2x.png";
  const footFormBg = "/assets/images/products/footForm.svg";
  const spwebImg = "/assets/images/products/spweb.svg";
  const pdLogo = "/assets/images/products/pd.svg";

  // contacxt form
  const [name, setName] = useState<string>("");
  const [mobile, setMobileNumber] = useState("");
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState(true);
  const [text, setText] = useState("");
  const [tLimit, setTLimit] = useState(false);
  const [mand, setMand] = useState(false);
  const [isValidName, setIsValidNAme] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [sucessMsg, setSucMsg] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMsg("");
    setSucMsg("");

    if (mobile.length < 10 || name.trim() === "") {
      setErrorMsg("Please provide valid mobile number and name.");
    } else {
      try {
        const response = await axios.post(
          "https://backend.ososweb.com/api/v1.0/connectus",
          {
            name,
            mobile,
            email,
            message: text,
          },
        );

        // Handle the response as needed
        console.log(response.data);
        setSucMsg(response.data.message);
      } catch (error) {
        // Handle errors
        console.error(error);
        setErrorMsg("An error occurred. Please try again later.");
      }
    }
  };

  const handleMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // Remove any non-digit characters from the input
    const sanitizedInput = input.replace(/\D/g, "");

    // Limit the input to 10 digits
    const truncatedInput = sanitizedInput.slice(0, 10);

    setMobileNumber(truncatedInput);
  };
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const formattedInput = input.replace(/[^A-Za-z\s]/g, "").substring(0, 25);
    setName(formattedInput);
    setIsValidNAme(formattedInput.trim().length > 0);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setEmail(input);
  };

  const handleInputBlur = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setIsValid(emailRegex.test(email));
  };
  const textBlur = () => {
    if (text.length <= 1500) {
      setTLimit(false);
    }
  };

  const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    if (input.length <= 1500) {
      setText(input);
    } else {
      setTLimit(true);
    }
  };

  const handleCloseModal = () => {
    setErrorMsg("");
    setSucMsg("");
  };

  return (
    <>
      <NavHeader imageUrl={pdLogo} />
      <div className="container-fluid">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column " style={{ marginTop: "100px" }}>
            <div className="txtCon  me-5 col-8 ">
              <h4 className="tiredText fs-2 ps-5  ">
                Tired of trying to reach your local community through outdated
                methods?
              </h4>
              <h1 className="tiredText ps-5 mt-5 d-flex">
                Look no further than
                <span className="ms-2 me-2">
                  <img src={line} alt="" style={{ marginTop: "-15px" }} />
                </span>
                <span className="d-flex flex-column">
                  <span className="sparksText">Spaarks</span>
                  <span className="fs-5 ms-5 smallTxt">
                    super app for local area
                  </span>
                </span>
              </h1>
            </div>
            {/* <img className="product-main-inner img-fluid" alt="" src="/rectangle-10@2x.png" /> */}
            <div
              className=" ptb-100 "
              style={{
                backgroundImage:
                  "url(" + "/assets/images/products/productBg.png" + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                maxHeight: "950px",
              }}
            >
              <div className="d-flex">
                <div className="col-8">
                  <a href="https://play.google.com/store/search?q=spaarks&c=apps&hl=en-IN">
                    <img
                      src=" /google-store.svg"
                      alt=""
                      className="tabImg animate cover"
                    />
                  </a>
                  <a href="https://apps.apple.com/in/app/spaarks/id1570373339">
                    <img
                      src="/apps-store.svg"
                      alt=""
                      className="tabImg animate cover"
                    />
                  </a>
                  <img src={webImgg} alt="" className="tabImg animate cover" />
                </div>
                <div className="col-4">
                  <div className="container-fluid">
                    <a href="https://www.ososweb.com">
                      <img
                        className="mob-img  cover"
                        alt=""
                        src="/iphone14promockup-3@2x.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ptb-100"></div>
              <div className="container-fluid ">
                <div className="row">
                  <img src={girlF} alt="" className="cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: "100px" }}
        >
          <div className="container-fluid">
            <div className="row">
              <img src={mob} alt="" className="cover ms-2 me-2 " />
            </div>
            <div className="row">
              <img
                src={mob2}
                alt=""
                className="cover ms-2 me-2 mob-2 ms-auto me-auto"
              />
            </div>
            <div className="row">
              <img
                src={mob3}
                alt=""
                className="cover ms-2 me-2 mob-2 ptb-100 ms-auto me-auto"
              />
            </div>
            <div className="row">
              <img
                src={mobMac}
                alt=""
                className="cover ms-2 me-2 mobMac mt-5 ptb-100 ms-auto me-auto"
              />
            </div>
          </div>
          <div className="h2 border-bottom mb-4">About Spaarks</div>

          <div className="mainCardCon  pb-3">
            <img src={card1} alt="" className="cardCon animate glow" />
            <img src={card2} alt="" className="cardCon animate glow" />
            <img src={card3} alt="" className="cardCon animate glow" />
          </div>
          <div className="mainCardCon">
            <img src={card4} alt="" className="cardCon animate glow" />
            <img src={card5} alt="" className="cardCon animate glow" />
            <img src={card6} alt="" className="cardCon animate glow" />
          </div>
        </div>
        <div
          className="row mt-4 d-flex flex-column justify-content-center align-items-center  "
          style={{ backgroundImage: `url("${footBg}")` }}
        >
          <div className="container-fluid">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="  ptb-100  d-flex position-relative ">
                <div className=" position-relative ">
                  <img src={footFormBg} alt="" className="footForm  mobHide " />
                </div>
                <div className="  ps-5 pt-5 z-2  midScCon position-absolute container-fluid mobMob">
                  <span className="getTouch ">Get in touch with us</span>
                  <div
                    className="d-flex flex-column  "
                    style={{ marginTop: "-15px" }}
                  >
                    <div className="form-menu  ">
                      <input
                        className="contactInp  mt-5 animate   "
                        type="text"
                        placeholder="Your name*"
                        value={name}
                        required
                        onChange={handleName}
                      />

                      <input
                        className="contactInp  mt-5 animate "
                        type="text"
                        max={10}
                        placeholder="Mobile number*"
                        value={mobile}
                        onChange={handleMobile}
                        required
                      />
                    </div>
                    <div className="d-flex mt-5  ">
                      <input
                        className="contactInp  animate"
                        type="email"
                        placeholder=" e-mail ID*"
                        value={email}
                        onBlur={handleInputBlur}
                        onChange={handleEmail}
                      />
                    </div>
                    {!isValid && (
                      <span className="text-danger">
                        please Enter a valid Email
                      </span>
                    )}
                    <div className="d-flex mt-5 ">
                      <textarea
                        className="custom-textarea contactInp  animate "
                        placeholder="Write your message here (Max:1000 Words)"
                        value={text}
                        onChange={handleMessage}
                        onBlur={textBlur}
                      />
                    </div>
                    {tLimit && (
                      <span className="text-danger">
                        You Can Write Upto 1500 letters
                      </span>
                    )}
                    {mand && (
                      <span className="text-danger mb-2">
                        Enter All Mandatory Feilds *{" "}
                      </span>
                    )}
                    <div
                      className="submit-btn animate mb-2 fs-5 "
                      onClick={handleSubmit}
                      style={{ minWidth: "80px" }}
                    >
                      <span className=" text-primary border-0">Submit</span>
                    </div>
                  </div>
                </div>
                {errorMsg && (
                  <PopupModal
                    errorMessage={errorMsg}
                    onClose={handleCloseModal}
                    heading={"Error"}
                    cssClass={"text-danger"}
                  />
                )}
                {sucessMsg && (
                  <PopupModal
                    errorMessage={sucessMsg}
                    onClose={handleCloseModal}
                    heading={"Done"}
                    cssClass={"text-success"}
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" footTxt d-flex flex-column">
            get spaarked ! Join Spaarks now!
            <a href="https://www.spaarksweb.com/">
              <img src={spwebImg} alt="" className="spImg mt-4 animate glow" />
            </a>
          </div>
          <div className="d-flex justify-content-around w-25 mt-1 mb-5  ">
            <a
              className="animate glow"
              href=" https://www.facebook.com/Spaarksapp"
            >
              <img src="/assets/smIcons/fbW.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href="https://instagram.com/spaarks.app"
            >
              <img src="assets/smIcons/instaW.svg" alt="" />
            </a>
            <a className="animate glow" href=" https://twitter.com/Spaarks_app">
              <img src="assets/smIcons/tww.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href=" https://www.linkedin.com/company/spaarks-app/"
            >
              <img src="assets/smIcons/link.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href=" https://www.youtube.com/channel/UCwyhvpr-O2X7PzOnFH9dW2w"
            >
              <img src="assets/smIcons/ytW.svg" alt="" />
            </a>
            {/* <a
              className="animate glow"
              href=" https://www.youtube.com/channel/UCwyhvpr-O2X7PzOnFH9dW2w"
            >
              <img src="assets/smIcons/tIc.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href=" https://www.youtube.com/channel/UCwyhvpr-O2X7PzOnFH9dW2w"
            >
              <img src="assets/smIcons/mIcon.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href=" https://www.youtube.com/channel/UCwyhvpr-O2X7PzOnFH9dW2w"
            >
              <img src="assets/smIcons/cat.svg" alt="" />
            </a>
            <a
              className="animate glow"
              href=" https://www.youtube.com/channel/UCwyhvpr-O2X7PzOnFH9dW2w"
            >
              <img src="assets/smIcons/Q.svg" alt="" />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductMain;
